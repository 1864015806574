import saucesImage from "../assets/images/meny/sauces.png"; // Justera sökvägen efter behov

export const pizzaKategorier = {
  "Pizza klass 1": [
    {
      namn: "1. Margherita",
      ingredienser: "Tomatsås, ost",
      pris: 110,
      familjepizza: 290,
      barnpizza: 95,
    },
    {
      namn: "2. Vesuvio",
      ingredienser: "Skinka",
      pris: 110,
      familjepizza: 290,
      barnpizza: 95,
    },
    {
      namn: "3. Al Funghi",
      ingredienser: "Champinjoner",
      pris: 110,
      familjepizza: 290,
      barnpizza: 95,
    },
    {
      namn: "4. Bolognese",
      ingredienser: "Köttfärssås",
      pris: 110,
      familjepizza: 290,
      barnpizza: 95,
    },
    {
      namn: "5. Altono",
      ingredienser: "Tonfisk, lök, paprika",
      pris: 110,
      familjepizza: 290,
      barnpizza: 95,
    },
    {
      namn: "6. Calzone",
      ingredienser: "Skinka (inbakad)",
      pris: 110,
      familjepizza: 290,
      barnpizza: 95,
    },
  ],
  "Pizza klass 2": [
    {
      namn: "7. Capricciosa",
      ingredienser: "Skinka, Champinjoner",
      pris: 115,
      familjepizza: 320,
      barnpizza: 100,
    },
    {
      namn: "8. Hawaii",
      ingredienser: "Skinka, Ananas",
      pris: 115,
      familjepizza: 320,
      barnpizza: 100,
    },
    {
      namn: "9. Salami",
      ingredienser: "Salami, Paprika, Lök",
      pris: 115,
      familjepizza: 320,
      barnpizza: 100,
    },
    {
      namn: "10. Opera",
      ingredienser: "Räkor, Tonfisk",
      pris: 115,
      familjepizza: 320,
      barnpizza: 100,
    },
    {
      namn: "11. Roma",
      ingredienser: "Skinka, Tonfisk",
      pris: 115,
      familjepizza: 320,
      barnpizza: 100,
    },
    {
      namn: "12. Skåne",
      ingredienser: "Skinka, Köttfärssås",
      pris: 115,
      familjepizza: 320,
      barnpizza: 100,
    },
    {
      namn: "13. Kj special",
      ingredienser: "Kebab, Nötkött, Jalapeno, Vitlök sås",
      pris: 125,
      familjepizza: 350,
      barnpizza: 115,
    },
    {
      namn: "14. Africana",
      ingredienser: "Skinka, Banan, Ananas, Curry",
      pris: 115,
      familjepizza: 320,
      barnpizza: 100,
    },
    {
      namn: "15. Tomasa",
      ingredienser: "Skinka, Räkor",
      pris: 115,
      familjepizza: 320,
      barnpizza: 100,
    },
    {
      namn: "16. Primavera",
      ingredienser: "Bacon, Ägg, Lök",
      pris: 115,
      familjepizza: 320,
      barnpizza: 100,
    },
    {
      namn: "17. Pescatore",
      ingredienser: "Tonfisk, Ananas, Räkor",
      pris: 115,
      familjepizza: 320,
      barnpizza: 100,
    },
    {
      namn: "18. La bamba",
      ingredienser: "Räkor, Ananas, Banan, Curry",
      pris: 115,
      familjepizza: 320,
      barnpizza: 100,
    },
  ],
  "Pizza klass 3": [
    {
      namn: "19. Amigo",
      ingredienser: "Champinjoner, Bacon, Salami, Lök",
      pris: 120,
      familjepizza: 340,
      barnpizza: 105,
    },
    {
      namn: "20. Magne",
      ingredienser: "Champinjoner, Skinka, Köttfärssås",
      pris: 120,
      familjepizza: 340,
      barnpizza: 105,
    },
    {
      namn: "21. Mamma mia",
      ingredienser: "Champinjoner, Skinka, Räkor",
      pris: 120,
      familjepizza: 340,
      barnpizza: 105,
    },
    {
      namn: "22. Prinsessa",
      ingredienser: "Skinka, Ananas, Champinjoner",
      pris: 120,
      familjepizza: 340,
      barnpizza: 105,
    },
    {
      namn: "23. Marinara",
      ingredienser: "Räkor, Musslor",
      pris: 120,
      familjepizza: 340,
      barnpizza: 105,
    },
    {
      namn: "24. Tefat",
      ingredienser: "Skinka (Dubbel calzone)",
      pris: 120,
      familjepizza: 340,
      barnpizza: 105,
    },
    {
      namn: "25. Amore",
      ingredienser: "Köttfärssås, Bacon, Ägg, Vitlök",
      pris: 120,
      familjepizza: 340,
      barnpizza: 105,
    },
    {
      namn: "26. Fantastico",
      ingredienser: "Salami, Köttfärssåa, Gorgonzola",
      pris: 120,
      familjepizza: 340,
      barnpizza: 105,
    },
    {
      namn: "27. Havet",
      ingredienser: "Tonfisk, Räkor, Musslor",
      pris: 120,
      familjepizza: 340,
      barnpizza: 105,
    },
    {
      namn: "28. Disco",
      ingredienser: "Skinka, Champinjoner, Räkor, Tonfisk, Lök",
      pris: 120,
      familjepizza: 340,
      barnpizza: 105,
    },
  ],
  "Pizza klass 4": [
    {
      namn: "29. Montecarlo",
      ingredienser: "Kyckling, Banan, Curry",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "30. Melodi",
      ingredienser: "Champinjoner, Räkor, Gorgonzola",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "31. La maffia",
      ingredienser: "Skinka, Lök, Vitlökskorv, Svartpeppar, Vitlök, Ägg",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "32. Porto fino",
      ingredienser: "Skinka, Räkor, Salami, Paprika, Lök",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "33. Vegetariana",
      ingredienser: "Champinjoner, Lök, Oliver, Paprika, Kronärtskocka, Ananas",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "34. Quattro",
      ingredienser: "Skinka, Champinjoner, Räkor, Musslor, Kronärtskocka",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "35. Italia",
      ingredienser: "Skinka, Köttfärssås, Salami, Lök, Bearnisesås (Stark)",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "36. Amadeus special",
      ingredienser: "Kyckling, Färska tomat, Champinjoner, Lök",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "37. Sultan",
      ingredienser: "Köttfärssås, Lök, Paprika, Bacon, Ägg, Tabasco",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "38. Torino",
      ingredienser: "Skinka, Champinjoner, Räkor, Salami, Feferoni, Vitlök",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "39. Chicago",
      ingredienser: "Kyckling, Bacon, Ananas, Banan, Curry",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "40. Kebabpizza",
      ingredienser: "Kebabkött, Valfri sås",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "41. Kebab special 1",
      ingredienser: "Kebabkött, Champinjoner, Lök, Feferoni, Valfri sås",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "42. Kebab special 2",
      ingredienser: "Kebabkött, Mixsallad, Valfri sås",
      pris: 135,
      familjepizza: 385,
      barnpizza: 110,
    },
    {
      namn: "43. Venezia",
      ingredienser: "Kebabkött, Skinka, Valfri sås",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
    {
      namn: "44. Båtpizza",
      ingredienser: "Kebabkött, Lök, Valfri sås (Halvinbakad)",
      pris: 125,
      familjepizza: 350,
      barnpizza: 110,
    },
  ],
  "Oxfilé pizzor": [
    {
      namn: "45. Robin hood",
      ingredienser: "Oxfilé, Ananas, Svamp, Bearnaisesås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "46. Atlantic",
      ingredienser: "Skinka, Champinjoner, Lök, Kebabkött, Valfri sås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "47. Kanonpizza",
      ingredienser: "Kebabkött, Ananas, Köttfärssås, Bearnaisesås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "48. Istra special",
      ingredienser: "Skinka, Oxfilé, Kebabkött, Räkor, Bearnaisesås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "49. Barbone",
      ingredienser: "Oxfilé, Ananas, Gorgonzola, Bearnaisesås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "50. Juventus",
      ingredienser: "Oxfilé, Champinjoner, Salami, Lök, Bearnaisesås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "51. Romana",
      ingredienser: "Skinka, Räkor, Oxfilé, Bearnaisesås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "52. Albino",
      ingredienser: "Skinka, Räkor, Oxfilé, Färsk vitlök, Bearnaisesås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "53. Favorit",
      ingredienser: "Oxfilé, Lök, Färsk tomat, Gorgonzola, Bearnaisesås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "54. Elay's special",
      ingredienser: "Oxfilé, Bacon, Champinjoner, Lök, Bearnaisesås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "55. Mancini",
      ingredienser: "Oxfilé, Färska tomat, Lök, Vitlök, Bearnaisesås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "56. Mats special",
      ingredienser:
        "Oxfilé, Ananas, Banan, Champinjoner, Lök, Vitlök, Svartpeppar",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
  ],
  "Mexikanska pizzor": [
    {
      namn: "57. Acapulco",
      ingredienser:
        "Oxfilé, Kryddmix, Lök, Vitlök, Champinjoner, Jalapeño, Tacosås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "58. Azteka",
      ingredienser: "Skinka, Jalapeño, Paprika, Tacosås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "59. Mexicana",
      ingredienser: "Köttfärssås, Lök, Jalapeño, Tacokryddmix, Feferoni",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
    {
      namn: "60. Rio",
      ingredienser: "Champinjoner, Jalapeño, Salami, Vitlökssås",
      pris: 130,
      familjepizza: 360,
      barnpizza: 120,
    },
  ],
  "Amerikanska panpizza": [
    {
      namn: "61. Cesar special",
      ingredienser: "Skinka, köttfärs, salami, oxfile, bearnaisesås",
      pris: 160,
    },
    {
      namn: "62. 4 Ost",
      ingredienser: "Gorgonzola, fetaost, mozarella, pizzaost",
      pris: 160,
    },
    {
      namn: "63. Kyckling Panpizza",
      ingredienser: "Kyckling, champinjoner, rödlök, pommes",
      pris: 160,
    },
  ],
  "Mozzarella pizzor": [
    {
      namn: "64. Mozzarella special",
      ingredienser: "Mozzarellaost, Tonfisk, Paprika, Färsk vitlök",
      pris: 140,
      familjepizza: 370,
      barnpizza: 130,
    },
    {
      namn: "65. Parma",
      ingredienser: "Mozzarellaost, Svamp, Tomat, Lufttorkad skinka, Ruccola",
      pris: 140,
      familjepizza: 370,
      barnpizza: 130,
    },
    {
      namn: "66. Salvatore",
      ingredienser:
        "Mozzarellaost, Svamp, Soltorkad tomat, Lufttorkad skinka, Oliver, Ruccola",
      pris: 140,
      familjepizza: 370,
      barnpizza: 130,
    },
    {
      namn: "67. Al pesto",
      ingredienser: "Mozzarellaost, Svamp, Soltorkade tomat, Lufttorkad skinka",
      pris: 140,
      familjepizza: 370,
      barnpizza: 130,
    },
    {
      namn: "68. Formagio",
      ingredienser: "Mozzarellaost, Gorgonzola, Vitost, Ruccola",
      pris: 140,
      familjepizza: 370,
      barnpizza: 130,
    },
  ],
  "Fläskfilé pizzor": [
    {
      namn: "69. Milano",
      ingredienser: "Lök, Skinka, Fläskfilé, Bearnaisesås",
      pris: 125,
      familjepizza: 360,
      barnpizza: 115,
    },
    {
      namn: "70. Hif",
      ingredienser: "Svamp, Paprika, Lök, Fläskfilé, Bearnaisesås",
      pris: 125,
      familjepizza: 360,
      barnpizza: 115,
    },
    {
      namn: "71. Rondel special",
      ingredienser: "Salami, Fläskfilé, Paprika, Soltorkad tomat, Bearnaisesås",
      pris: 125,
      familjepizza: 360,
      barnpizza: 115,
    },
    {
      namn: "72. Venedig",
      ingredienser: "Bacon, Paprika, Fläskfilé, Bearnaisesås",
      pris: 125,
      familjepizza: 360,
      barnpizza: 115,
    },
    {
      namn: "73. Black & white",
      ingredienser: "Lök, Fläskfilé, Oxfilé, Bearnaisesås",
      pris: 130,
    },
  ],
  "Nya pizzor": [
    {
      namn: "82. Husets",
      ingredienser:
        "Oxfilé, Paprika, Soltorkade tomat, Lök, Ruccola, Bearnaisesås",
      pris: 130,
      familjepizza: 360,
    },

    {
      namn: "87. Zlatan special",
      ingredienser: "Kebabkött, Pommes, Valfri sås",
      pris: 145,
      familjepizza: 395,
    },

    {
      namn: "89. Liverpool special",
      ingredienser: "Kyckling, Pommes, Vitlök sås",
      pris: 145,
      familjepizza: 395,
    },
  ],
  "Kebab rätter (Nötkött/Gyros)": [
    {
      namn: "Kebab i bröd",
      ingredienser: "Hembakat bröd, Kebabkött, Mixsallad, Feferoni, Valfri sås",
      pris: 105,
    },
    {
      namn: "Kebabrulle",
      ingredienser:
        "Hembakat rullat bröd, Kebabkött, Mixsallad, Feferoni, Valfri sås",
      pris: 125,
    },
    {
      namn: "Kebabtallrik",
      ingredienser: "Pommes/Ris, Kebabkött, Mixsallad, Feferoni, Valfri sås",
      pris: 125,
      beskrivning: "(Välj mellan pommes eller ris)",
    },
    {
      namn: "Katerina special",
      ingredienser: "Kebabrulle, Endast kött, Mild sås, Extra mycket ananas",
      pris: 125,
    },
  ],
  Kycklingkebab: [
    {
      namn: "Kyckling i bröd",
      ingredienser: "Hembakat bröd, Kyckling, Mixsallad, Feferoni, Valfri sås",
      pris: 105,
    },
    {
      namn: "Kycklingrulle",
      ingredienser:
        "Hembakat rullat bröd, Kyckling, Mixsallad, Feferoni, Valfri sås",
      pris: 125,
    },
    {
      namn: "Kycklingtallrik",
      ingredienser: "Pommes, Kyckling, Mixsallad, Feferoni, Valfri sås",
      pris: 125,
    },
  ],
  "Falafel rätter": [
    {
      namn: "Falafel i bröd",
      ingredienser: "Hembakat bröd, Falafel, Mixsallad, Feferoni, Valfri sås",
      pris: 100,
    },
    {
      namn: "Falafelrulle",
      ingredienser:
        "Hembakat rullat bröd, Falafel, Mixsallad, Feferoni, Valfri sås",
      pris: 115,
    },
    {
      namn: "Falafeltallrik",
      ingredienser: "Pommes, Falafel, Mixsallad, Feferoni, Valfri sås",
      pris: 115,
    },
  ],
  "Halloumi rätter": [
    {
      namn: "Halloumirulle",
      ingredienser: "Halloumi, Grönsaker, Sås i rullbröd",
      pris: 115,
    },
    {
      namn: "Halloumi i bröd",
      ingredienser: "Halloumi, Grönsaker, Sås i bröd",
      pris: 110,
    },
    {
      namn: "Halloumiburgare",
      ingredienser: "Halloumi, Sallad, Tomat, Sås i hamburgerbröd",
      pris: 115,
    },
    {
      namn: "Halloumisallad",
      ingredienser: "Halloumi, Blandad sallad, Grönsaker, Dressing",
      pris: 115,
    },
    {
      namn: "Halloumitallrik",
      ingredienser: "Halloumi, Pommes frites, Sallad, Sås",
      pris: 115,
    },
  ],
  Sallader: [
    {
      namn: "Räksallad",
      ingredienser:
        "Isbergssallad, Tomat, Gurka, Majs, Champinjoner, Räkor, Ananas",
      pris: 120,
    },
    {
      namn: "Kycklingsallad",
      ingredienser: "Isbergssallad, Tomat, Gurka, Majs, Kyckling, Ananas",
      pris: 120,
    },
    {
      namn: "Skinksallad",
      ingredienser: "Isbergssallad, Tomat, Gurka, Majs, Skinka, Ananas",
      pris: 120,
    },
    {
      namn: "Tonfisksallad",
      ingredienser: "Isbergssallad, Tomat, Gurka, Majs, Paprika, Lök, Tonfisk",
      pris: 120,
    },
    {
      namn: "Falafel sallad",
      ingredienser:
        "Isbergssallad, Tomat, Gurka, Majs, Paprika, Lök, Falafel, Vitost",
      pris: 120,
    },
    {
      namn: "Grekisksallad",
      ingredienser: "Isbergssallad, Tomat, Vitost, Paprika, Lök, Oliver",
      pris: 120,
    },
    {
      namn: "Vegetarisk sallad",
      ingredienser:
        "Isbergssallad, Tomat, Gurka, Majs, Champinjoner, Lök, Oliver, Paprika, Ananas, Kronärtskocka",
      pris: 120,
    },
    {
      namn: "Kebabsallad",
      ingredienser: "Kebabkött, Sallad, Tomat, Gurka, Lök, Feferoni",
      pris: 120,
    },
  ],
  "Grill rätter": [
    {
      namn: "Hamburgertallrik 200g med ost",
      ingredienser: "Pommes",
      pris: 125,
    },
    {
      namn: "Hamburgertallrik 200g med bacon",
      ingredienser: "Pommes",
      pris: 125,
    },
    {
      namn: "Mexikansk hamburgertallrik 200g",
      ingredienser: "Pommes, (Stark)",
      pris: 125,
    },
    {
      namn: "Hamburgare 100g",
      ingredienser: "Pommes",
      pris: 90,
    },
    {
      namn: "Hamburgare 200g",
      ingredienser: "Pommes",
      pris: 115,
    },
    {
      namn: "Kyckling nuggets",
      ingredienser: "Sallad, Pommes, Sås",
      pris: 115,
    },
    {
      namn: "Chilli cheese (4 / 8 st)",
      pris: 45, // Pris för 4 st
      storPris: 80, // Pris för 8 st
      beskrivning: "4 / 8 st",
    },
    {
      namn: "BBQ chicken wings (4st / 8st)",
      pris: 40, // Pris för 4 st
      storPris: 70, // Pris för 8 st
      beskrivning: "4st / 8st",
    },
    {
      namn: "Schnitzel tallrik 250g",
      ingredienser: "Pommes, Grönsaker",
      pris: 130,
    },
    {
      namn: "Mozzarella sticks 5st",
      pris: 65,
    },
    {
      namn: "Lökringar 6st",
      pris: 35,
    },
    {
      namn: "Pommes",
      pris: 50,
    },
  ],
  "Langos rätter": [
    {
      namn: "Langos kebab",
      ingredienser: "Ost, Grönsaker, Kebab, Sås",
      pris: 110,
    },
    {
      namn: "Langos kyckling",
      ingredienser: "Ost, Grönsaker, Kyckling, Sås",
      pris: 110,
    },
    {
      namn: "Langos tonfisk",
      ingredienser: "Ost, Grönsaker, Räkor, Tonfisk, Sås",
      pris: 110,
    },
    {
      namn: "Langos hawaii",
      ingredienser: "Ost, Grönsaker, Skinka, Sås",
      pris: 110,
    },
  ],
  "Veganska alternativ": [
    {
      namn: "Växtbaserat protein",
      ingredienser: "Kebabrulle/Kebab i bröd/Kebabtallrik/Pizza",
      pris: 25,
    },
  ],
  "Extra tillbehör": [
    {
      namn: "Kött / Fisk (Vanlig pizza)",
      pris: 20,
    },
    {
      namn: "Kött / Fisk (Familjepizza)",
      pris: 40,
    },
    {
      namn: "Våra hemgjorda såser",
      ingredienser:
        "Vitlök, Mild, Stark, Rhode island, Bearnaisesås, Remouladesås, BBQ sås, Tzatziki sås, Curry mango, Laktos sås",
      pris: 15,
      bild: saucesImage,
    },
    {
      namn: "Sallad",
      pris: 10,
    },
    {
      namn: "Glutenfria Pizzor/Rulle/Calzone",
      pris: 35,
    },
    {
      namn: "Barnpizzor",
      pris: -10,
    },
    {
      namn: "Pommes",
      pris: 50,
    },
    {
      namn: "Pizzasallad",
      pris: 10,
    },
    {
      namn: "Glutenfritt hamburgerbröd",
      pris: 20,
      beskrivning: "Extra kostnad för glutenfritt bröd",
    },
  ],
};

export const gruppNamn = Object.keys(pizzaKategorier);
